import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, InjectionToken, Injector, PLATFORM_ID, makeStateKey, TransferState } from '@angular/core';

import { environment } from 'src/environments/environment';

export const APP_ENVIRONMENT_SETTINGS = new InjectionToken<EnvironmentSettings>("AppEnvironmentSettings");

@Injectable({
    providedIn: 'root'
})
export class EnvironmentService {

    public settings: EnvironmentSettings;

    constructor(injector: Injector,
        transferState: TransferState,
        @Inject(PLATFORM_ID) private platformId: Object) {
        const storeKey = makeStateKey<EnvironmentSettings>('environmentSettings');
        if (isPlatformBrowser(this.platformId)) {
            this.settings = transferState.get(storeKey, environment.defaultSettings!);
        } else {
            this.settings = injector.get<EnvironmentSettings>(APP_ENVIRONMENT_SETTINGS);
            transferState.set(storeKey, this.settings);
        }
    }
}

export interface EnvironmentSettings {
    PublicApiBaseUrl: string;
    BaseDomainName?: string;
    BlogUrl: string;
    SentryDsn?: string;
    GTMSiteId?: string;
}
