import { DOCUMENT, Location } from '@angular/common';
import { Inject, Injectable, Optional } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { SERVER_URL } from '../../tokens/server-url.token';
import { EnvironmentService } from 'src/app/core/services/environment/environment.service';

@Injectable({
    providedIn: 'root'
})
export class MetaTagsService {

    private metaTags: {
        "description": MetaTag,
        "og:title": MetaTag,
        "og:description": MetaTag,
        "og:image": MetaTag,
        "og:image:secure_url": MetaTag,
        "twitter:text:title": MetaTag,
        "twitter:image": MetaTag
    } = {
        "description": { isFacebook: false },
        "og:title": { isFacebook: true },
        "og:description":{ isFacebook: true },
        "og:image": { isFacebook: true },
        "og:image:secure_url": { isFacebook: true },
        "twitter:text:title":{ isFacebook: false },
        "twitter:image": { isFacebook: false }
    }

    constructor(private titleService: Title,
        private metaService: Meta,
        private location: Location,
        @Inject(DOCUMENT) private document: Document,
        private environmentService: EnvironmentService,
        private router: Router,
        @Optional() @Inject(SERVER_URL) private serverUrl: string) {}

    public init() {
        this.router.events.pipe(filter(e => e instanceof NavigationEnd))
            .subscribe(() => this.updateSeoSettingsFromCurrentRoute());
    }

    private updateSeoSettingsFromCurrentRoute() {
        let currentRoute: ActivatedRoute = this.router.routerState.root;
        let seoSettings: SeoSettings = {};
        while (currentRoute.firstChild) {
            currentRoute = currentRoute.firstChild;
            if (currentRoute.snapshot.data.seo) {
                seoSettings = Object.assign(seoSettings, currentRoute.snapshot.data.seo);
            }
        }
        this.setSeoSettings(seoSettings);
    }

    public setSeoSettings(seo: SeoSettings) {
        this.setTitle(seo.title);
        let absoluteImageUrl: string | undefined = undefined;
        if (seo.imageUrl) {
            absoluteImageUrl = seo.imageUrl.startsWith("http") || seo.imageUrl.startsWith("//") ? seo.imageUrl
                : `${this.document.location.origin}${this.location.prepareExternalUrl(seo.imageUrl)}`;
        }
        this.setSocialMediaTags(seo.title, seo.description, absoluteImageUrl || "");
    }

    private setTitle(title?: string): void {
        this.titleService.setTitle(title||"");
    }

    private setSocialMediaTags(title?: string, description?: string, imageUrl?: string): void {
        this.metaTags.description.value = description;
        this.metaTags['og:title'].value = title;
        this.metaTags['og:description'].value = description;
        this.metaTags['og:image'].value = imageUrl;
        this.metaTags['og:image:secure_url'].value = imageUrl;
        this.metaTags['twitter:text:title'].value = title;
        this.metaTags['twitter:image'].value = imageUrl;
        Object.keys(this.metaTags).forEach(key => {
            const metaTag = (<any>this.metaTags)[key] as MetaTag;
            if (metaTag.isFacebook) {
                this.metaService.updateTag({ property: key, content: metaTag.value||"" });
            } else {
                this.metaService.updateTag({ name: key, content: metaTag.value||"" });
            }
        });
    }
}

export interface MetaTag {
    value?: string;
    isFacebook: boolean;
}

export interface SeoSettings {
    title?: string;
    description?: string;
    imageUrl?: string;
}